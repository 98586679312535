var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{style:({height:'80px', width:'96%', marginLeft:'2%', marginTop:'0%'})},[_c('div',{staticClass:"search_class"},[(!_vm.lan_chosen)?_c('button',{style:({borderStyle:'none', fontSize:'16px',borderRadius:'3px 0px 0px 3px',
        boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
         backgroundColor:'#0A6338',
         width:'200px',color:'white'}),attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.open_lan()}}},[_vm._v(" Vilket län bor du i? "),_c('b-icon',{attrs:{"icon":"caret-down"}})],1):_vm._e(),(_vm.lan_chosen)?_c('button',{style:({borderStyle:'none', borderRadius:'3px 0px 0px 3px',
          boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
          backgroundColor:'#0A6338',color:'white', width:'200px' }),attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.open_lan()}}},[_vm._v(_vm._s(this.lan_name)+" "),_c('b-icon',{attrs:{"icon":"caret-down"}})],1):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_item),expression:"search_item"}],staticClass:"input_search",attrs:{"type":"text","id":"one","name":"input","placeholder":"Vad vill du laga? ","value":""},domProps:{"value":(_vm.search_item)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnter.apply(null, arguments)},function($event){return _vm.get_company_search(_vm.search_item)}],"input":function($event){if($event.target.composing)return;_vm.search_item=$event.target.value}}}),(this.search_item_flag==1)?_c('b-icon',{style:({ width:'16px',color:'black', marginLeft:'85%',position:'absolute',marginTop:'9%', cursor:'pointer'}),attrs:{"icon":"x-lg"},on:{"click":function($event){return _vm.open_search()}}}):_vm._e()],1),(this.län_opened)?_c('div',{style:({height:'200px',border:0, position:'absolute',
          borderWidth:'0',
          boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
          borderRadius:'0px 3px 3px 0px',marginTop:'0%',overflowY:'auto',borderRadius:'3px',
          opacity:'100%',zIndex:13, width:'55%',marginTop:'0.5%',
           backgroundColor:'white',color:'#0A6338',marginLeft:'0%'})},_vm._l((_vm.län_list),function(län,idx){return _c('div',{key:idx,style:({marginLeft:'4%'}),on:{"click":function($event){_vm.choose_län_func(län.id,län.lan)}}},[_c('div',{style:({height:'27px',fontSize:'16px', display:'flex',marginTop:'1%'})},[_c('div',{style:({marginLeft:'1%',marginTop:'0.5%', color:'black'})},[_vm._v(" "+_vm._s(län.lan)+" ")])])])}),0):_vm._e()]),(this.search_item_flag==1 )?_c('div',{style:({height:'200px',border:0, position:'absolute',borderWidth:'0',
        boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',borderRadius:'8px',marginTop:'1%',
        opacity:'100%',zIndex:13, backgroundColor:'white',width:'65%', marginLeft:'34%'})},[(_vm.search_list.length ==0 )?_c('div',{style:({color:'black'})},[_vm._v(" Tyvärr hittade vi inga resultat denna gången men skicka gärna en förfrågan så ska vi försöka hjälpa dig ")]):_vm._e(),(_vm.search_list.length !=0)?_c('div',{style:({height:'200px',width:'100%',overflowY:'auto'})},_vm._l((_vm.search_list),function(search,idx){return _c('div',{key:idx,style:({marginLeft:'1%',textAlign:'left',  width:'99%',height:'27px',  fontWeight:'bold', marginTop:'1%'})},[_c('div',{style:({width:'100%',height:'27px', display:'flex',marginTop:'1%'}),on:{"click":function($event){return _vm.to_category(search.id,search.category)}}},[_c('div',{style:({marginLeft:'1%',marginTop:'1%',width:'100%',fontSize:'17px',lineHeight:'13px',fontWeight:500, height:'35px',color:'black'})},[_vm._v(" "+_vm._s(search.category)+" ")])])])}),0):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }