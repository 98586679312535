<template lang="html">
  <div class="">
    <div :style="{height:'80px', width:'96%', marginLeft:'2%', marginTop:'0%'}">
      <div class="search_class">
        <button type="button"
          :style="{borderStyle:'none', fontSize:'16px',borderRadius:'3px 0px 0px 3px',
          boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
           backgroundColor:'#0A6338',
           width:'200px',color:'white'}"  v-if="!lan_chosen"
           @click="open_lan()" name="button">
           Vilket län bor du i?
           <b-icon  icon="caret-down" ></b-icon>
         </button>
        <button type="button"   :style="{borderStyle:'none', borderRadius:'3px 0px 0px 3px',
            boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
            backgroundColor:'#0A6338',color:'white', width:'200px' }"
            v-if="lan_chosen" name="button"
            @click="open_lan()">{{this.lan_name}}
          <b-icon  icon="caret-down" ></b-icon>
        </button>

              <input v-model="search_item" type="text" id="one" name="input" v-on:keyup.enter="onEnter"
              v-on:keyup="get_company_search(search_item)"
              class="input_search"
              placeholder="Vad vill du laga? " value="">
              <b-icon :style="{ width:'16px',color:'black', marginLeft:'85%',position:'absolute',marginTop:'9%', cursor:'pointer'}"
              icon="x-lg"        @click="open_search()"  v-if="this.search_item_flag==1"   />
              <!-- <b-icon icon="search"    @click="get_results()"
              :style="{ cursor:'pointer', borderRadius:'50%', backgroundColor:'white' ,color:'#0A6338',height:'40px',marginTop:'6%', padding:'1%',width:'40px', marginLeft:'3%'}"/> -->
      </div>
      <div v-if= "this.län_opened" :style="{height:'200px',border:0, position:'absolute',
            borderWidth:'0',
            boxShadow: '0px 4px 9px 0px rgba(0, 0, 0, 0.25)',
            borderRadius:'0px 3px 3px 0px',marginTop:'0%',overflowY:'auto',borderRadius:'3px',
            opacity:'100%',zIndex:13, width:'55%',marginTop:'0.5%',
             backgroundColor:'white',color:'#0A6338',marginLeft:'0%'}">

      <div  v-for="(län,idx) in län_list" :style="{marginLeft:'4%'}"
      :key="idx" @click="choose_län_func(län.id,län.lan)" >
        <div :style="{height:'27px',fontSize:'16px', display:'flex',marginTop:'1%'}" >
          <div :style="{marginLeft:'1%',marginTop:'0.5%', color:'black'}">
            {{län.lan}}
          </div>
        </div>
       </div>
      </div>
    </div>
     <div  v-if="this.search_item_flag==1 "
          :style="{height:'200px',border:0, position:'absolute',borderWidth:'0',
          boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',borderRadius:'8px',marginTop:'1%',
          opacity:'100%',zIndex:13, backgroundColor:'white',width:'65%', marginLeft:'34%'}">
          <div v-if="search_list.length ==0 " :style="{color:'black'}">
            Tyvärr hittade vi inga resultat denna gången men skicka gärna en förfrågan så ska vi försöka hjälpa dig
          </div>

          <div v-if="search_list.length !=0" :style="{height:'200px',width:'100%',overflowY:'auto'}" >
             <div v-for="(search,idx) in search_list" :key="idx"
             :style="{marginLeft:'1%',textAlign:'left',  width:'99%',height:'27px',  fontWeight:'bold', marginTop:'1%'}">
             <div :style="{width:'100%',height:'27px', display:'flex',marginTop:'1%'}"
               @click="to_category(search.id,search.category)">
            <div :style="{marginLeft:'1%',marginTop:'1%',width:'100%',fontSize:'17px',lineHeight:'13px',fontWeight:500, height:'35px',color:'black'}">
              {{search.category}}
            </div>
          </div>
        </div>
        </div>
     </div>

  </div>
</template>

<script>
import axios from 'axios';
import {API_URL} from '../../config.js'
import router from '../router/index'
import {bus} from '../main'
export default {
  data:function(){
    return{
      lan_name:"",
      lan_id:0,
      lan_chosen:false,
      län_list:[],
      län_opened:false,
      category_id:0,
      category_name:'',
      search_item_flag:0,
      search_item:'',
      search_list:[]
    }
  },
  methods:{
    open_search(){
      this.search_item =''
      this.search_item_flag=0
    },
    get_company_search(){
      if(this.search_item.length >0){
        this.search_item_flag =1
        var self = this
        axios({
            method:'get',
            url: API_URL+ '/search_categories'+'?search_item='+this.search_item+'&lan_id='+this.lan_id,
          })
          .then(function(response){
              self.search_list=response.data
          })
      }else {
        this.search_item_flag =0
      }
    },
    to_category(category_id,category_name){
        this.$forceUpdate()
        axios({
          method:'post',
          url: API_URL+ '/click_category',
          data:{'category_id':category_id, lan_id:this.lan_id}
        })
        .then(function(){
         })
        this.search_item=category_name
         this.category_id=category_id
         this.category_name=category_name
         this.search_item_flag=0
        router.push({ path:"result_page", query:{category_id:category_id, from_page:'category', category_name:category_name } }).catch(()=>{})
    },
    open_lan(){
        if(this.län_opened){
          this.län_opened = false
        }
        else{
          this.län_opened = true
        }
    },
    choose_län_func(lan_id,lan_name){
      this.lan_id=lan_id
      this.lan_name =lan_name
      this.lan_chosen = true
      this.län_opened=false
      var lan_filter_start = true
      this.$store.commit('set_lan', {"lan_id":this.lan_id,"lan":this.lan_name, "lan_chosen": this.lan_chosen, "lan_filter_start":lan_filter_start})
      bus.$emit("remove_filter");

      bus.$emit("remove_filter");

      // axios({
      //   method:'post',
      //   url: API_URL+ '/click_lan',
      //   data:{'lan_id':lan_id}
      // })
      // .then(function(){
      //  })

    }
  },
  mounted(){
    this.län_list =[
      {'id':1, 'lan': 'Blekinge län'   },
      {'id':2, 'lan': 'Dalarnas län'   },
      {'id':3, 'lan': 'Gotlands län'   },
      {'id':4, 'lan': 'Gävleborgs län'   },
      {'id':5, 'lan': 'Hallands län'   },
      {'id':6, 'lan': 'Jämtlands län'   },
      {'id':7, 'lan': 'Jönköpings län'   },
      {'id':8, 'lan': 'Kalmar län'   },
      {'id':9, 'lan': 'Kronobergs län'   },
      {'id':10, 'lan': 'Norrbottens län'   },
      {'id':11, 'lan': 'Skåne län'   },
      {'id':12, 'lan':  'Stockholms län'  },
      {'id':13, 'lan':  'Södermanlands län'  },
      {'id':14, 'lan':  'Uppsala län'  },
      {'id':15, 'lan':  'Värmlands län'  },
      {'id':16, 'lan':  'Västerbottens län'  },
      {'id':17, 'lan':  'Västernorrlands län'  },
      {'id':18, 'lan':  'Västmanlands län'  },
      {'id':19, 'lan':  'Västra Götalands län'  },
      {'id':20, 'lan':  'Örebro län'  },
      {'id':21, 'lan':  'Östergötlands län'  }
    ]

    if(this.$store.state.lan_object ==undefined){
      this.lan_name =''
      this.lan_id =0
      this.lan_chosen = false
    }
    else {
      this.lan_name =this.$store.state.lan_object.lan
      this.lan_id = this.$store.state.lan_object.lan_id
      this.lan_chosen = this.$store.state.lan_object.lan_chosen

    }
  }
}
</script>

<style lang="css" scoped>
.search_class{
  width: 100%;
  height: 80px;
  margin-top: 4%;
  /* background: lightgrey; */
  /* rgba(196, 196, 196, 0.32); */
  border-radius: 3px;
  display: flex;
  /* border-style: solid; */
}

</style>
